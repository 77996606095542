<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_eng" placeholder="กรอกชื่อภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="นามสกุล (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_eng" placeholder="กรอกนามสกุลภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname_th" placeholder="กรอกชื่อภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="นามสกุล (Th)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname_th" placeholder="กรอกนามสกุลภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2"
                                label="ตำแหน่งวิชาการ (Eng)" label-for="input-2">
                                <b-form-input id="input-2" v-model="academic_position_eng" placeholder="กรอกตำแหน่งทางวิชาการภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2"
                                label="ตำแหน่งวิชาการ (Th)" label-for="input-2">
                                <b-form-input id="input-2" v-model="academic_position_th" placeholder="กรอกตำแหน่งทางวิชาการภาษาไทย"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="สังกัด (Eng)"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_eng" placeholder="กรอกสังกัดภาษาอังกฤษ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="กรอกสังกัดภาษาไทย"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="affiliation_th"
                                    placeholder="Enter firstname"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2"
                                label="ความเชี่ยวชาญ (Eng)" label-for="input-2">
                                <b-form-input id="input-2" v-model="expertise_eng"
                                    placeholder="กรอกความเชี่ยวชาญภาษาอังกฤษ"></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2"
                                label="ความเชี่ยวชาญ (Th)" label-for="input-2">
                                <b-form-input id="input-2" v-model="expertise_th"
                                    placeholder="กรอกความเชี่ยวชาญภาษาไทย"></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(saveExpert())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import expertService from "../../../../../services/expert.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            expertId: null,
            firstname_eng: null,
            lastname_eng: null,
            firstname_th: null,
            lastname_th: null,
            academic_position_eng: null,
            academic_position_th: null,
            affiliation_eng : null,
            affiliation_th: null,
            expertise_eng: null,
            expertise_th: null,
            show: true,
            role: null,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การตั้งค่า",
                    href: "#",
                },
                {
                    text: "ผู้ทรงคุณวุฒิ",
                    to: { name: "experts" },
                },
                {
                    text: "เพิ่มผู้ทรงคุณวุฒิ",
                    active: true,
                },
            ],
        };
    },

    methods: {
        saveExpert: async function () {
            this.isLoading = true;
            if (this.isValidate()) {
                let newR = {
                    firstname_eng: this.firstname_eng,
                    lastname_eng: this.lastname_eng,
                    firstname_th: this.firstname_th,
                    lastname_th: this.lastname_th,
                    academic_position_eng: this.academic_position_eng,
                    academic_position_th: this.academic_position_th,
                    affiliation_eng: this.affiliation_eng,
                    affiliation_th: this.affiliation_th,
                    expertise_eng: this.expertise_eng,
                    expertise_th: this.expertise_th
                }
                await expertService.addExpert(newR);
                history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.firstname_eng) this.errors.push("Firstname Eng required.");
            if (!this.lastname_eng) this.errors.push("Lastname Eng required.");
            if (!this.firstname_th) this.errors.push("Firstname Th required.");
            if (!this.lastname_th) this.errors.push("Lastname Th required.");
            if (!this.academic_position_eng) this.errors.push("Academic position Eng required.");
            if (!this.academic_position_th) this.errors.push("Academic position Th required.");

            if (!this.errors.length) return true;
        },

    },
    mounted() {
    }

};
</script>